import request from '../api/axios'

let baseUrl = process.env.VUE_APP_API_HOST

export const HOST = '..'

export const submit = (data) => {
    return request({
        url: baseUrl +'/cgi-bin/sumbitMessage',
        method: 'post',
        data
    })
}